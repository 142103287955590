// eslint-disable-next-line import/named
import { html } from "lit";
import { isAutoSaving } from "../../../../quotes/views/../../components/save-workflow";

import { PromiseSnippet, PromiseTemplate } from "../../../../quotes/views/../../components/ui/events";
import { ModalDialog } from "../../../../quotes/views/../../components/ui/modal-base";

import { tlang } from "../../../../quotes/views/../../language/lang";


export class CustomFrameLineItemDialog extends ModalDialog {
    ok = false;
    interval: NodeJS.Timeout | null = null;
    newConfigurationId?: number;
    existingItemsProviderReferenceIds: string[] = [];

    protected async canClose(): Promise<boolean> {
        if (isAutoSaving())
            this.save();
        return true;
    }
    protected async getTitle(): PromiseSnippet {
        return tlang`%%custom-frame%%`;
    }
    protected modalSize(): string {
        return "modal-fullscreen";
    }
    protected save() {

        this.ok = true;
        this.hideModal();
    }

    protected async closeAndSave() {
        if (isAutoSaving()) {
            this.save();
        } else
            this.hideModal();
    }

    protected onFrameCreated = (ev: CustomEvent) => {
        this.newConfigurationId = ev.detail.configurationId;
        this.save();
    }

    protected async bodyTemplate(): PromiseTemplate {
        return html`<grid-frame-wizard @frame-created=${this.onFrameCreated}></grid-frame-wizard>`;
    }
}

export class StandardFrameLineItemDialog extends CustomFrameLineItemDialog {
    protected async getTitle(): PromiseSnippet {
        return tlang`%%standard-frame%%`;
    }

    protected async bodyTemplate(): PromiseTemplate {
        const existingItemsProviderReferenceIdsJson = JSON.stringify(this.existingItemsProviderReferenceIds);
        return html`<st-resource-frame-wizard slot="body" other-configuration-ids="${existingItemsProviderReferenceIdsJson}" @frame-created=${this.onFrameCreated}></st-resource-frame-wizard>`;
    }
}

export default async function gridFrameWizardDialog(){
    const dlg = new CustomFrameLineItemDialog();
    await dlg.showModal();
    if (dlg.ok) {
       return dlg.newConfigurationId;
    }
    throw Error('Grid Frame Wizard not successful');
}

export async function framePickerDialog(providerReferenceIds: string[]){
    const dlg = new StandardFrameLineItemDialog();
    dlg.existingItemsProviderReferenceIds = providerReferenceIds;
    await dlg.showModal();
    if (dlg.ok) {
       return dlg.newConfigurationId;
    }
    throw Error('Frame picker not successful');
}

